#presentation {
  min-height: 50rem;
  position: relative;
  overflow: hidden;
}

#presentation > video {
  @apply absolute top-1/2 left-1/2 min-h-full min-w-full -translate-y-1/2 -translate-x-1/2 brightness-50;
  object-fit: cover;
}

#presentation > *:not(video) {
  @apply relative z-10;
}

.header__plataformas {
  @apply text-right text-white text-2xl hidden lg:block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.plataforma_display {
  @apply text-white h-full xl:h-80 w-full xl:w-48 flex rounded-xl justify-end flex-col bg-primary-400 mt-5 xl:mt-12 relative z-10 overflow-hidden;
  transition: transform 0.2s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0.5em;
}

@media (min-width: 1280px) {
  .plataforma_display {
    max-width: 24%;
  }
}

.plataforma_display::after {
  @apply h-full w-full absolute z-20 top-0 bottom-0 left-0 right-0;
  content: '';
  transition: opacity 0.2s ease-in-out;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9));
}

.plataforma_display::before {
  @apply h-full w-full absolute z-20 top-0 bottom-0 left-0 right-0;
  content: '';
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #719f2a);
}

.plataforma_display > span {
  @apply relative z-30;
}

.plataforma_display > .description {
  @apply overflow-hidden;
  max-height: 0px;
  transition: max-height 0.2s ease-in-out;
}

.plataforma_display:hover > .description {
  max-height: 10rem;
}

.plataforma_display:hover {
  transform: scale(1.02) translateY(-10px);
}

.plataforma_display:hover::after {
  opacity: 0;
}

.plataforma_display:hover::before {
  opacity: 1;
}

.plataforma_button {
  @apply w-full mt-4 py-2 rounded-2xl text-base text-center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
}

.mini_square__panel {
  @apply overflow-hidden relative text-white shrink-0 text-xl font-bold w-40 h-40 rounded-lg flex items-start justify-end p-4 flex-col border-4 border-primary-300/0 cursor-pointer;
  transition: all 0.2s ease-in-out;
}

.mini_square__panel:hover {
  transform: scale(1.04);
}

.mini_square__panel:hover::before {
  opacity: 0;
}

.mini_square__panel::before {
  content: '';
  @apply absolute top-0 left-0 right-0 bottom-0 z-10 opacity-70;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), black);
  transition: all 0.2s ease-in-out;
}

.mini_square__panel::after {
  transition: all 0.2s ease-in-out;
}

.mini_square_bg {
  @apply absolute w-full h-full top-0 bottom-0 left-0 right-0;
  object-fit: cover;
}

.mini_square__panel > .new_panel {
  @apply text-gray-1300 bg-white rounded-lg mb-2 lowercase text-sm opacity-80;
  padding: 0.2rem 0.4rem;
}

.mini_square__panel > *:not(img) {
  @apply relative z-10;
}

.selected {
  @apply border-primary-300 relative;
}

.selected::after {
  @apply absolute top-0 left-0 bottom-0 right-0  bg-primary-300/40 z-10;
  content: '';
}

.selected::before {
  @apply opacity-0;
}

.selected > *:not(img) {
  @apply relative z-20;
}

.squares_holder {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
}

.form_input {
  @apply w-full mt-4 px-2 py-4 rounded-lg text-base;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

.form_input ~ p {
  @apply text-sm font-semibold mt-2 opacity-0;
  color: red;
  transition: all 0.2s ease-in-out;
}

.form_input.error {
  border-color: red;
}

.form_input.error + p {
  @apply opacity-100;
}

.form_send {
  @apply text-white text-2xl py-4 w-full rounded-full mt-6;
  background: linear-gradient(to right, #538200, #98d035, #9de917, #82cb01);
  background-size: 200% 200%;
  background-position: 0% 0%;
  transition: all 0.2s ease-in-out;
}

.form_send:hover {
  background-position: 100% 100%;
}

.panel_controls {
  @apply h-16 w-16 mt-10 bg-primary-400 rounded-full hover:bg-primary-600 text-white;
  transition: all 0.2s ease-in-out;
}

.panel_controls > i {
  @apply text-3xl;
}

#contact_form_feedback {
  @apply scale-75 opacity-0 pointer-events-none transition-all;
}

#contact_form_feedback.active {
  @apply scale-100 opacity-100 pointer-events-none transition-all;
}

.card_totals {
  @apply rounded-2xl px-4 py-6 shadow-md w-full lg:w-1/3 xl:w-1/5 my-2 box-border flex flex-col justify-between;
  background: linear-gradient(to right, #538200, #98d035);
}

.card_totals_button {
  @apply text-white rounded-md py-2 px-4 hover:bg-white hover:text-primary-600 transition-all;
  border: 1px solid white;
}

.cards_holder {
  @apply flex flex-wrap py-0 justify-center mb-20 -mt-28 relative z-10 gap-4;
}
