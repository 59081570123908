#presentation {
  min-height: 50rem;
  position: relative;
  overflow: hidden;
}

#presentation > video {
  min-height: 100%;
  min-width: 100%;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
}

#presentation > :not(video) {
  z-index: 10;
  position: relative;
}

.header__plataformas {
  text-align: right;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  font-size: 1.5rem;
  line-height: 2rem;
  display: none;
}

@media (min-width: 1024px) {
  .header__plataformas {
    display: block;
  }
}

.header__plataformas {
  border-bottom: 1px solid #fff6;
}

.plataforma_display {
  z-index: 10;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(152 208 53 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: .75rem;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 1.25rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1280px) {
  .plataforma_display {
    height: 20rem;
    width: 12rem;
    margin-top: 3rem;
  }
}

.plataforma_display {
  background-repeat: no-repeat;
  background-size: cover;
  padding: .5em;
  transition: transform .2s ease-in-out;
}

@media (min-width: 1280px) {
  .plataforma_display {
    max-width: 24%;
  }
}

.plataforma_display:after {
  z-index: 20;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(#0000004d, #000000e6);
  transition: opacity .2s ease-in-out;
  position: absolute;
  inset: 0;
}

.plataforma_display:before {
  z-index: 20;
  height: 100%;
  width: 100%;
  content: "";
  opacity: 0;
  background: linear-gradient(#0000004d, #719f2a);
  transition: opacity .2s ease-in-out;
  position: absolute;
  inset: 0;
}

.plataforma_display > span {
  z-index: 30;
  position: relative;
}

.plataforma_display > .description {
  max-height: 0;
  transition: max-height .2s ease-in-out;
  overflow: hidden;
}

.plataforma_display:hover > .description {
  max-height: 10rem;
}

.plataforma_display:hover {
  transform: scale(1.02)translateY(-10px);
}

.plataforma_display:hover:after {
  opacity: 0;
}

.plataforma_display:hover:before {
  opacity: 1;
}

.plataforma_button {
  width: 100%;
  text-align: center;
  background-color: #fff3;
  border: 1px solid #fff;
  border-radius: 1rem;
  margin-top: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.mini_square__panel {
  height: 10rem;
  width: 10rem;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-width: 4px;
  border-color: #9ed54a00;
  border-radius: .5rem;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mini_square__panel:hover {
  transform: scale(1.04);
}

.mini_square__panel:hover:before {
  opacity: 0;
}

.mini_square__panel:before {
  content: "";
  z-index: 10;
  opacity: .7;
  background: linear-gradient(#00000080, #000);
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 0;
}

.mini_square__panel:after {
  transition: all .2s ease-in-out;
}

.mini_square_bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

.mini_square__panel > .new_panel {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  text-transform: lowercase;
  --tw-text-opacity: 1;
  color: rgb(73 73 73 / var(--tw-text-opacity) );
  opacity: .8;
  border-radius: .5rem;
  margin-bottom: .5rem;
  padding: .2rem .4rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.mini_square__panel > :not(img) {
  z-index: 10;
  position: relative;
}

.selected {
  --tw-border-opacity: 1;
  border-color: rgb(158 213 74 / var(--tw-border-opacity) );
  position: relative;
}

.selected:after {
  z-index: 10;
  content: "";
  background-color: #9ed54a66;
  position: absolute;
  inset: 0;
}

.selected:before {
  opacity: 0;
}

.selected > :not(img) {
  z-index: 20;
  position: relative;
}

.squares_holder {
  box-shadow: 0 0 60px #0003;
}

.form_input {
  width: 100%;
  border: 1px solid #0000004d;
  border-radius: .5rem;
  margin-top: 1rem;
  padding: 1rem .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all .2s ease-in-out;
}

.form_input ~ p {
  opacity: 0;
  color: red;
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  transition: all .2s ease-in-out;
}

.form_input.error {
  border-color: red;
}

.form_input.error + p {
  opacity: 1;
}

.form_send {
  width: 100%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  background: linear-gradient(to right, #538200, #98d035, #9de917, #82cb01) 0 0 / 200% 200%;
  border-radius: 9999px;
  margin-top: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  transition: all .2s ease-in-out;
}

.form_send:hover {
  background-position: 100% 100%;
}

.panel_controls {
  height: 4rem;
  width: 4rem;
  --tw-bg-opacity: 1;
  background-color: rgb(152 208 53 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: 9999px;
  margin-top: 2.5rem;
}

.panel_controls:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(83 130 0 / var(--tw-bg-opacity) );
}

.panel_controls {
  transition: all .2s ease-in-out;
}

.panel_controls > i {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

#contact_form_feedback {
  pointer-events: none;
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  opacity: 0;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#contact_form_feedback.active {
  pointer-events: none;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  opacity: 1;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card_totals {
  box-sizing: border-box;
  width: 100%;
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-between;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 1.5rem 1rem;
  display: flex;
}

@media (min-width: 1024px) {
  .card_totals {
    width: 33.3333%;
  }
}

@media (min-width: 1280px) {
  .card_totals {
    width: 20%;
  }
}

.card_totals {
  background: linear-gradient(to right, #538200, #98d035);
}

.card_totals_button {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  border-radius: .375rem;
  padding: .5rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.card_totals_button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(83 130 0 / var(--tw-text-opacity) );
}

.card_totals_button {
  border: 1px solid #fff;
}

.cards_holder {
  z-index: 10;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: -7rem;
  margin-bottom: 5rem;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative;
}

/*# sourceMappingURL=index.a9a18830.css.map */
